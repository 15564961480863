import {
  SIGNUP_USER,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  SET_USER,
  GET_USER,
  LOGOUT,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
  UPDATE_PROFILE,
} from "../actions/index";

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  payload: payload,
});

export const signupUser = (payload) => ({
  type: SIGNUP_USER,
  payload: payload,
});

export const loginUserSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload: payload,
});
export const loginUserError = (payload) => ({
  type: LOGIN_ERROR,
  payload: payload,
});
export const forgotPassword = (payload) => ({
  type: FORGOT_PASSWORD,
  payload: payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload: payload,
});

export const getUser = (payload) => ({
  type: GET_USER,
  payload: payload,
});

export const logout = (payload) => ({
  type: LOGOUT,
  payload: payload,
});

export const facebookLogin = (payload) => ({
  type: FACEBOOK_LOGIN,
  payload: payload,
});

export const googleLogin = (payload) => ({
  type: GOOGLE_LOGIN,
  payload: payload,
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE,
  payload: payload,
});

