export const LIST_PLANS = "LIST_PLANS";
export const RESET_PAYMENT = "RESET_PAYMENT";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_COUPON = "GET_COUPON";
export const SET_PAYMENT = "SET_PAYMENT";
export const SUCCESS = "SUCCESS";
export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const UPDATE_DEFAULT_PAYMENT_METHOD = "UPDATE_DEFAULT_PAYMENT_METHOD";
export const REMOVE_PAYMENT_METHOD = "REMOVE_PAYMENT_METHOD";
export const ERROR = "ERROR";

