import { mergeMap, map, catchError } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import {
  List_USERS,
  GET_USER_PROFILE,
  GET_STATISTICS,
  GET_USERS_BY_DATE,
  GET_USER_SUBSCRIPTION,
} from "../actions/adminActions";
import { adminSuccess } from "../actionCreators";
import { db } from "../../firebase/index";
import { getHeader, authRequest } from "../../common/utils/auth";
import { adminBaseUrl, baseUrl } from "../../environment";
import { doc, getDoc, collection, getDocs, query, orderBy } from "firebase/firestore";

export const listUsersEpic = (action$) =>
  action$.pipe(
    ofType(List_USERS),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          let limit = action.payload.apiPayload.limit;
          let offset = action.payload.apiPayload.offset;
          let email = action.payload.apiPayload.email;
          let firstName = action.payload.apiPayload.first_name;
          let lastName = action.payload.apiPayload.last_name;
          let country = action.payload.apiPayload.country;
          let paid = action.payload.apiPayload.paid;
          let sign_in_device = action.payload.apiPayload.sign_in_device;
          let gender = action.payload.apiPayload.gender;
          let orderingAttribute = action.payload.apiPayload.orderingAttribute;
          let orderingSequence = action.payload.apiPayload.orderingSequence;
          let coupon = action.payload.apiPayload.coupon;
          let company_name = action.payload.apiPayload.company_name;
          localStorage.setItem("paid", paid);
          localStorage.setItem("sign_in_device", sign_in_device);
          console.log("-In admin epics", orderingAttribute, orderingSequence);

          ajax
            .getJSON(
              `${adminBaseUrl}/list?limit=${limit}&offset=${offset}&email=${email}&first_name=${firstName}&last_name=${lastName}&country=${country}&sign_in_device=${sign_in_device}&paid=${paid}&gender=${gender}&ordering_attribute=${orderingAttribute}&ordering_sequence=${orderingSequence}&coupon=${coupon}&company_name=${company_name}`,
              getHeader()
            )
            .subscribe(
              (response) => {
                const users = response.data;
                if (action.payload.onSuccess) {
                  action.payload.onSuccess(users);
                }
                resolve(users);
              },
              (err) => {
                reject(err?.response);
              }
            );
        })
      ).pipe(
        map((response) => adminSuccess({ users: response })),
        catchError((error) => error)
      )
    )
  );

export const getStatisticsEpic = (action$) =>
  action$.pipe(
    ofType(GET_STATISTICS),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.getJSON(`${adminBaseUrl}/statistics`, getHeader()).subscribe(
            (response) => {
              const statistics = response?.data;
              resolve(statistics);
            },
            (err) => {
              reject(err?.response?.data);
            }
          );
        })
      ).pipe(
        map((response) => adminSuccess({ statistics: response })),
        catchError((error) => Promise.resolve(error))
      )
    )
  );

export const getPaidUsers = () => {
  return new Promise((resolve, reject) => {
    const url = `${adminBaseUrl}/list`;
    authRequest(url, "GET")
      .then((res) => {
        const data = res.data.data.users;
        console.log(data);
        const users = {};
        for (let user of data) {
          users[user.uid] = user;
        }
        resolve(users);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTherapyDates = () => {
  return new Promise((resolve, reject) => {
    const therapySessionsRef = collection(db, "therapy_sessions");

    getDocs(therapySessionsRef)
      .then((querySnapshot) => {
        const documents = {};
        querySnapshot.forEach((doc) => {
          const recordsRef = collection(doc.ref, "records");

          getDocs(recordsRef)
            .then((recordsSnapshot) => {
              const recordData = recordsSnapshot.docs.map((record) => record.id);
              documents[doc.id] = recordData;
            })
            .catch((error) => {
              reject(error); // Reject the promise on record retrieval error
            });
        });

        resolve(documents);
      })
      .catch((error) => {
        reject(error); // Reject the promise on initial query error
      });
  });
};

export const userStats = () => {
  return new Promise((resolve, reject) => {
    const userProgressStatsRef = collection(db, "user_progress_stats");

    getDocs(userProgressStatsRef)
      .then((querySnapshot) => {
        const documents = {};
        querySnapshot.forEach((doc) => {
          documents[doc.id] = doc.data();
        });
        console.log("success", documents);
        resolve(documents);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const getUserProfileEpic = (action$) =>
  action$.pipe(
    ofType(GET_USER_PROFILE),
    mergeMap((action) =>
      from(
        Promise.all([
          // Fetch user profile
          getDoc(doc(db, "users", action.payload.uid)).then((docSnap) => {
            if (docSnap.exists()) {
              return docSnap.data();
            } else {
              return {};
            }
          }),
          // Fetch user therapy records
          getDocs(query(collection(db, "assessments", action.payload.uid, "records"), orderBy("date", "desc"))).then(
            (querySnapshot) => {
              if (!querySnapshot.empty) {
                return querySnapshot.docs.map((doc) => doc.data());
              } else {
                return [];
              }
            }
          ),
          // Fetch user progress stats
          getDoc(doc(db, "user_progress_stats", action.payload.uid)).then((docSnap) => {
            if (docSnap.exists()) {
              return docSnap.data();
            } else {
              return {};
            }
          }),
          // Fetch therapy sessions
          getDocs(
            query(collection(db, "therapy_sessions", action.payload.uid, "records"), orderBy("date", "desc"))
          ).then((querySnapshot) => {
            if (!querySnapshot.empty) {
              return querySnapshot.docs.map((doc) => doc.data());
            } else {
              return [];
            }
          }),
          // Fetch coupons
          getDocs(collection(db, "coupons")).then((querySnapshot) => {
            if (!querySnapshot.empty) {
              return querySnapshot.docs.map((doc) => doc.data());
            } else {
              return [];
            }
          }),
          // External AJAX call for admin data
          new Promise((resolve, reject) => {
            let uid = action.payload.uid;
            console.log("-In admin epics", uid);
            ajax.getJSON(`${adminBaseUrl}/${uid}`, getHeader()).subscribe(
              (response) => {
                const user = response.data.user[0];
                resolve(user);
              },
              (err) => {
                reject(err.response);
              }
            );
          }),
        ])
      ).pipe(
        map((response) =>
          adminSuccess({
            user: response[0],
            assessments: response[1],
            progressStats: response[2],
            therapySessions: response[3],
            customCoupons: response[4],
            users: response[5],
          })
        ),
        catchError((error) => Promise.resolve(error))
      )
    )
  );
export const filterUsersByDateEpic = (action$) => {
  return action$.pipe(
    ofType(GET_USERS_BY_DATE),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax
            .post(
              `${adminBaseUrl}/get-user-count`,
              { start_date: action.payload.start_date, end_date: action.payload.end_date },
              getHeader()
            )
            .subscribe(
              (response) => {
                const count = response.response.data.count;
                if (action.payload.onSuccess) {
                  action.payload.onSuccess(count);
                }
                resolve(count + 1);
              },
              (err) => {
                reject(err.response);
              }
            );
        })
      ).pipe(
        map((response) => adminSuccess({ users_by_date_count: response })),
        catchError((error) => Promise.resolve(error))
      )
    )
  );
};

export const getUserSubscriptionEpic = (action$) => {
  return action$.pipe(
    ofType(GET_USER_SUBSCRIPTION),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.get(`${baseUrl}/subscription/retrieve/${action.payload.sub_id}`, getHeader()).subscribe(
            (response) => {
              const subscriptionStatus = response.response.data.status;
              if (action.payload.onSuccess) {
                action.payload.onSuccess(subscriptionStatus);
              }
              resolve(subscriptionStatus);
            },
            (err) => {
              console.log(err);
              reject(err.response);
            }
          );
        })
      ).pipe(
        map((response) => adminSuccess({ subscriptionStatus: response })),
        catchError((error) => Promise.resolve(error))
      )
    )
  );
};
