import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux";
import * as serviceWorker from "./serviceWorker";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripeConfig } from "./environment";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import "./index.css";

// Sentry.init({
//   dsn: "https://6fad576f218b4b6cb426e5beba868969@o549665.ingest.sentry.io/5672695",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.5,
// });

const stripePromise = loadStripe(stripeConfig.publicKey);

const root = document.getElementById("root");

createRoot(root).render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <App />
    </Provider>
  </Elements>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
