import React from "react";
import Routes from "./routes";
import { ConfigProvider, theme } from "antd";
import "./App.scss";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
function App() {
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Routes />
    </ConfigProvider>
  );
}

export default App;

