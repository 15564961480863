import { mergeMap, map, catchError } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";

import {
  LIST_PLANS,
  CREATE_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_COUPON,
  GET_CARD_DETAILS,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
} from "../actions/paymentActions";

import { success, paymentError } from "../actionCreators";
import { db } from "../../firebase";
import { baseUrl } from "../../environment";
import { getHeader } from "../../common/utils/auth";
import { doc, setDoc } from "firebase/firestore";

export const updatePromo = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/subscription/coupon`, { method: "PUT", body: JSON.stringify(data), headers: getHeader() })
      .then(async (res) => {
        if (res.status !== 200) {
          reject(await res.json());
        }
        resolve(await res.json());
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const verifyStripeCoupon = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/verify-stripe-coupon?stripe_coupon=${data.stripe_coupon}`, {
      method: "GET",
      headers: getHeader(),
    })
      .then(async (res) => {
        resolve(await res.json());
      })
      .catch(async (e) => {
        reject(e);
      });
  });
};

export const listPlansEpic = (action$) =>
  action$.pipe(
    ofType(LIST_PLANS),
    mergeMap((action) =>
      ajax.getJSON(`${baseUrl}/all`, getHeader()).pipe(
        map((response) => success({ plans: response.data.data })),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const getCustomerEpic = (action$) =>
  action$.pipe(
    ofType(GET_CUSTOMER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.getJSON(`${baseUrl}/invoices/${action.payload.id}`, getHeader()).subscribe(
            (response) => {
              resolve(response);
              if (action.payload.onSuccess) {
                action.payload.onSuccess(response.data);
              }
            },
            (err) => {
              reject(err?.response?.data);
            }
          );
        })
      ).pipe(
        map((response) => success({ customer: response.data })),
        catchError((error) => Promise.resolve(paymentError({ message: error })))
      )
    )
  );
function logrewardFull(email) {
  if (window.rewardful) {
    window.rewardful("convert", { email: email });
  }
}

export const createCustomerEpicDeprecated = (action$) =>
  action$.pipe(
    ofType(CREATE_CUSTOMER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          if (action.payload.custId) {
            ajax.getJSON(`${baseUrl}/invoices/${action.payload.custId}`, getHeader()).subscribe(
              (customerResponse) => {
                const customer = customerResponse.data;
                ajax
                  .post(
                    `${baseUrl}/subscription/create`,
                    {
                      customer: action.payload.custId,
                      items: [{ plan: action.payload.planId }],
                      expand: ["latest_invoice.payment_intent"],
                      coupon: action.payload.coupon,
                      trial_period_days: action.payload.trial_period_days,
                      is_trial_applied: action.payload.is_trial_applied,
                    },
                    getHeader()
                  )
                  .subscribe(
                    (subscriptionResponse) => {
                      const subscription = subscriptionResponse.response.data;
                      resolve({ customer: customer, subscription: subscription });
                      console.log("created");
                      action.payload.onCreate(customer, subscription);
                    },
                    (err) => {
                      console.log("err1", err?.response?.data);
                      reject(err?.response?.data);
                    }
                  );
              },
              (err) => {
                console.log("err2", err?.response?.data);
                reject(err?.response?.data);
              }
            );
          } else {
            ajax.post(`${baseUrl}/customer/create`, action.payload.customer, getHeader()).subscribe(
              (customerResponse) => {
                const customer = customerResponse.response.data;
                ajax
                  .post(
                    `${baseUrl}/subscription/create`,
                    {
                      customer: customer.id,
                      items: [{ plan: action.payload.planId }],
                      expand: ["latest_invoice.payment_intent"],
                      coupon: action.payload.coupon,
                      trial_period_days: action.payload.trial_period_days,
                      is_trial_applied: action.payload.is_trial_applied,
                    },
                    getHeader()
                  )
                  .subscribe(
                    (subscriptionResponse) => {
                      const subscription = subscriptionResponse.response.data;
                      resolve({ customer: customer, subscription: subscription });
                      action.payload.onCreate(customer, subscription);
                    },
                    (err) => {
                      reject(err?.response?.data);
                    }
                  );
              },
              (err) => {
                reject(err?.response?.data);
              }
            );
          }
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const createCustomerEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_CUSTOMER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          const email = action.payload?.customer?.email;
          if (action.payload.coupon === "") {
            delete action.payload.coupon;
          }

          const customer = action.payload?.customer;
          ajax
            .post(
              `${baseUrl}/subscription/create_new`,
              {
                email: email,
                payment_method: customer.payment_method,
                invoice_settings: customer.invoice_settings,
                items: [{ plan: action.payload.planId }],
                coupon: action.payload.coupon,
                trial_period_days: action.payload.trial_period_days,
                firebase_coupon: action.payload.firebaseCoupon?.name,
              },
              getHeader()
            )
            .subscribe(
              (subscriptionResponse) => {
                logrewardFull(email);
                const subscriptionData = subscriptionResponse.response.data;
                resolve({ subscription: subscriptionData });
                action.payload.onCreate(subscriptionData);
              },
              (err) => {
                reject(err?.response?.status);
              }
            );
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => {
          return Promise.resolve(paymentError(error));
        })
      )
    )
  );

export const updateSubscirptionEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SUBSCRIPTION),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.put(`${baseUrl}/subscription/update`, action.payload, getHeader()).subscribe(
            (subscriptionResponse) => {
              const userRef = doc(db, "users", action.payload.uid);
              setDoc(userRef, { planId: action.payload.planId }, { merge: true }).then(() => {
                resolve({});
                action.payload.onUpdate("Subscription changed successfully");
              });
            },
            (err) => {
              action.payload.onError(err.response.data?.message);
              reject(err.response.data);
            }
          );
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const cancelSubscirptionEpic = (action$) =>
  action$.pipe(
    ofType(CANCEL_SUBSCRIPTION),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.put(`${baseUrl}/subscription/cancel`, action.payload, getHeader()).subscribe(
            (subscriptionResponse) => {
              resolve({});
              action.payload.onCancel();
            },
            (err) => {
              reject(err.response);
            }
          );
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const getCouponEpic = (action$) =>
  action$.pipe(
    ofType(GET_COUPON),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.getJSON(`${baseUrl}/coupon-code/${action.payload.id}`, getHeader()).subscribe(
            (res) => {
              let couponRes = res.data;
              action.payload.onSuccess(couponRes);
              resolve(couponRes);
            },
            (err) => {
              reject(err.response);
              action.payload.onError();
            }
          );
        })
      ).pipe(
        map((response) => success({})),
        catchError((error) => Promise.resolve(paymentError({})))
      )
    )
  );

export const getCardDetails = (action$) =>
  action$.pipe(
    ofType(GET_CARD_DETAILS),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.getJSON(`${baseUrl}/payment_methods/${action.payload.customerId}`, getHeader()).subscribe(
            (customResponse) => {
              let response = customResponse?.data;
              resolve(response);
            },
            (err) => {
              action.payload.onError(err?.response?.data?.message);
              reject(err?.response?.data);
            }
          );
        })
      ).pipe(
        map((response) => success({ paymentMethods: response })),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const updateDefaultPaymentMethodEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_DEFAULT_PAYMENT_METHOD),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.put(`${baseUrl}/subscription/update`, action.payload, getHeader()).subscribe(
            (subscriptionResponse) => {
              let response = subscriptionResponse?.data;
              action.payload.onSuccess(response);
              resolve({});
            },
            (err) => {
              action.payload.onError(err);
              reject(err?.message);
            }
          );
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );

export const removePaymentMethodEpic = (action$) =>
  action$.pipe(
    ofType(REMOVE_PAYMENT_METHOD),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          ajax.post(`${baseUrl}/payment_methods/remove`, action.payload, getHeader()).subscribe(
            (paymentMethodRemovalResponse) => {
              let response = paymentMethodRemovalResponse?.data;
              action.payload.onSuccess(response);
              resolve({ response });
            },
            (err) => {
              reject(err?.response?.data);
            }
          );
        })
      ).pipe(
        map((response) => success(response)),
        catchError((error) => Promise.resolve(paymentError(error)))
      )
    )
  );
