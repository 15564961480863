import { combineEpics } from "redux-observable";
import {
  loginUserEpic,
  signupUserEpic,
  forgotPasswordEpic,
  logoutUserEpic,
  getUserEpic,
  googleLoginEpic,
  facebookLoginEpic,
  updateProfileEpic,
} from "./userEpics";

import {
  listPlansEpic,
  createCustomerEpic,
  getCustomerEpic,
  updateSubscirptionEpic,
  cancelSubscirptionEpic,
  getCouponEpic,
  getCardDetails,
  updateDefaultPaymentMethodEpic,
  removePaymentMethodEpic,
} from "./paymentEpics";

import {
  listUsersEpic,
  getUserProfileEpic,
  getStatisticsEpic,
  filterUsersByDateEpic,
  getUserSubscriptionEpic,
} from "./adminEpics";

export const rootEpic = combineEpics(
  loginUserEpic,
  signupUserEpic,
  forgotPasswordEpic,
  logoutUserEpic,
  getUserEpic,
  googleLoginEpic,
  facebookLoginEpic,
  updateProfileEpic,
  listPlansEpic,
  createCustomerEpic,
  getCustomerEpic,
  updateSubscirptionEpic,
  cancelSubscirptionEpic,
  listUsersEpic,
  getUserProfileEpic,
  getCouponEpic,
  getCardDetails,
  updateDefaultPaymentMethodEpic,
  removePaymentMethodEpic,
  getStatisticsEpic,
  filterUsersByDateEpic,
  getUserSubscriptionEpic
);
