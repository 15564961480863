import axios from "axios";

export const setCookie = (cookieName, cookieValue, exdays) => {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + currentDate.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
};
export const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
};
export const removeCookie = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const isAdmin = (user) => {
  let isAdmin = user?.claims.admin === true ? true : false;
  return isAdmin;
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const getHeader = () => {
  return {
    Authorization: `Bearer ${getCookie("token")}`,
    "Content-Type": "application/json",
  };
};

export const setCookiesFromState = (userState) => {
  setCookie("uid", userState?.user?.uid, 14);
  setCookie("token", userState?.user?.stsTokenManager?.accessToken, 14);
};

export const setCookiesFromFirebase = (user, idToken, isAdmin) => {
  setCookie("uid", user.uid, 14);
  setCookie("token", idToken, 14);
  setCookie("isAdmin", isAdmin, 14);
};

export const authRequest = async (url, type, data = null) => {
  return await axios({
    url: url,
    method: type,
    headers: getHeader(),
    data: data,
  });
};
