// export const firebaseConfig = {
//   apiKey: "AIzaSyCQVUja6MN7EaaLnM8OssfXZrkShYY1vAw",
//   authDomain: "audio-cardio-staging-265903.firebaseapp.com",
//   databaseURL: "https://audio-cardio-staging-265903.firebaseio.com",
//   projectId: "audio-cardio-staging-265903",
//   storageBucket: "audio-cardio-staging-265903.appspot.com",
//   messagingSenderId: "160432042616",
//   appId: "1:160432042616:web:2beea9be0e4e8d018c738d",
//   measurementId: "G-43JWL5N9MJ",
// };
// export const stripeConfig = {
//   publicKey: "pk_test_Y7cIuYsU7fljoSJfqBj75mjV00wrZ0wdy8",
// };

// export const baseUrl = "https://audio-cardio-staging-265903.appspot.com/api/v1/stripe";
// export const appURL = "https://audio-cardio-staging-265903.appspot.com/api/v1";
// export const adminBaseUrl = "https://audio-cardio-staging-265903.appspot.com/api/v1/users";
// export const appURLV2 = "https://audio-cardio-staging-265903.appspot.com/api/v2";

//Production

export const firebaseConfig = {
  apiKey: "AIzaSyA1opvs6GrzT-bT_KRvyZ6kSqEUr_fS9Ko",
  authDomain: "audio-cardio-166e2.firebaseapp.com",
  databaseURL: "https://audio-cardio-166e2.firebaseio.com",
  projectId: "audio-cardio-166e2",
  storageBucket: "audio-cardio-166e2.appspot.com",
  messagingSenderId: "889922806452",
  appId: "1:889922806452:web:0631458043fc54ba992de1",
  measurementId: "G-2K825NP3W5",
};
export const stripeConfig = {
  publicKey: "pk_live_q3RKLQme8qZw7FSjO8SPQIa400d8VuzhRd",
};
export const baseUrl = "https://audio-cardio-166e2.appspot.com/api/v1/stripe";
export const appURL = "https://audio-cardio-166e2.appspot.com/api/v1";
export const adminBaseUrl = "https://audio-cardio-166e2.appspot.com/api/v1/users";
export const appURLV2 = "https://audio-cardio-166e2.appspot.com/api/v2";

// local;
// export const baseUrl = "http://127.0.0.1:8000/api/v1/stripe";
// export const appURL = "http://127.0.0.1:8000/api/v1";
// export const adminBaseUrl = "http://127.0.0.1:8000/api/v1/users";
// export const appURLV2 = "http://127.0.0.1:8000/api/v2";

