import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";
import userReducer from "./reducers/userReducer";
import { paymentReducer } from "./reducers/paymentReducer";
import { adminReducer } from "./reducers/adminReducer";

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: {
    user: userReducer,
    paymentReducer: paymentReducer,
    adminReducer: adminReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
});

epicMiddleware.run(rootEpic);

export default store;
