import React, { useState } from "react";
import { auth } from "../firebase/index";
import { SyncOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, getUser } from "../redux/actionCreators";
import { toJSON } from "../common/utils/serilizer";
import { isAdmin, setCookiesFromFirebase, setCookiesFromState } from "../common/utils/auth";

function PrivateRoute({ state, user, history, component: Component, ...rest }) {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isVerified, setVerified] = useState(false);

  function verify_user() {
    if (!userState.user) {
      let unsubscribe = auth.onAuthStateChanged(function (user) {
        if (user) {
          user.getIdTokenResult(true).then((result) => {
            setCookiesFromFirebase(user, result?.token, isAdmin(result));
          });
          setVerified(true);
          if (!userState.user) {
            dispatch(setUser(toJSON(user))); //set firebase user in store
            dispatch(getUser(toJSON(user))); //Get corresponding firestore user
          }
        } else {
          history.push("/login");
        }
        if (unsubscribe) {
          unsubscribe();
        }
      });
    } else {
      setVerified(true);
      setCookiesFromState(userState);
    }
    return true;
  }

  if (!isVerified) {
    verify_user();
    return <SyncOutlined spin />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default withRouter(PrivateRoute);
