import {
  List_USERS,
  ADMIN_SUCCESS,
  ADMIN_ERROR,
  GET_USER_PROFILE,
  GET_STATISTICS,
  GET_USERS_BY_DATE,
  GET_USER_SUBSCRIPTION,
} from "../actions/index";

export const listUsers = (payload) => ({
  type: List_USERS,
  payload: payload,
});

export const getStatistics = (payload) => ({
  type: GET_STATISTICS,
  payload: payload,
});

export const adminSuccess = (payload) => ({
  type: ADMIN_SUCCESS,
  payload: payload,
});

export const adminError = (payload) => ({
  type: ADMIN_ERROR,
  payload: payload,
});

export const getUserProfile = (payload) => ({
  type: GET_USER_PROFILE,
  payload: payload,
});

export const getUsersByDate = (payload) => ({
  type: GET_USERS_BY_DATE,
  payload: payload,
});

export const getUserSubscription = (payload) => ({
  type: GET_USER_SUBSCRIPTION,
  payload: payload,
});
