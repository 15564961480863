export const LOGIN_USER = "LOGIN_USER";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const SIGNUP_USER = "SIGNUP_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGOUT = "LOGOUT";

