import {
  LIST_PLANS,
  SUCCESS,
  RESET_PAYMENT,
  ERROR,
  UPDATE_SUBSCRIPTION,
  GET_CUSTOMER,
  CREATE_CUSTOMER,
  SET_PAYMENT,
  GET_CARD_DETAILS,
} from "../actions";

const initialState = {
  plans: [],
  customer: null,
  subscription: null,
  isLoading: false,
  error: null,
};

export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION:
      return {
        plans: state.plans,
        customer: state.customer,
        subscription: state.subscription,
        isLoading: true,
        error: null,
      };
    case GET_CARD_DETAILS:
      return { ...state, card: state.card, isLoading: true };
    case SET_PAYMENT:
      return {
        plans: action.payload.plans || state.plans,
        customer: action.payload.customer || state.customer,
        subscription: action.payload.subscription || state.subscription,
        isLoading: false,
        error: null,
      };
    case GET_CUSTOMER:
      return {
        plans: state.plans,
        customer: state.customer,
        subscription: state.subscription,
        isLoading: true,
        error: null,
      };
    case CREATE_CUSTOMER:
      return {
        plans: state.plans,
        customer: state.customer,
        subscription: state.subscription,
        isLoading: true,
        error: state.error,
      };
    case LIST_PLANS:
      return {
        plans: state.plans,
        customer: state.customer,
        subscription: state.subscription,
        isLoading: true,
        error: null,
      };
    case SUCCESS:
      return {
        plans: action.payload.plans || state.plans,
        customer: action.payload.customer || state.customer,
        subscription: action.payload.subscription || state.subscription,
        isLoading: false,
        paymentMethods: action.payload.paymentMethods || state.paymentMethods,
        error: null,
      };
    case RESET_PAYMENT:
      return {
        plans: [],
        customer: null,
        subscription: null,
        isLoading: false,
        error: null,
      };
    case ERROR:
      let errorMsg = action?.payload?.message;
      if (!errorMsg) {
        errorMsg = action?.payload;
      }
      return {
        plans: state.plans,
        customer: state.customer,
        subscription: state.subscription,
        isLoading: false,
        error: state.error || errorMsg,
      };
    default:
      return state;
  }
}
