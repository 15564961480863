import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// HOC Templates

import UserPrivate from "../auth/UserPrivate";
import AdminPrivate from "../auth/AdminPrivate";

// Pages

const Home = lazy(() => import("../components/UserComponents/Home"));
const Users = lazy(() => import("../components/AdminComponents/Users"));
const ListPartners = lazy(() => import("../components/AdminComponents/Partners/listPartners"));
const Login = lazy(() => import("../components/AuthComponents/Login"));
const ListBatches = lazy(() => import("../components/AdminComponents/BatchCoupons/listBatches"));
const ViewPromoCodes = lazy(() => import("../components/AdminComponents/BatchCoupons/viewPromoCodes"));
const NewPartner = lazy(() => import("../components/AdminComponents/Partners/create"));
const Signup = lazy(() => import("../components/AuthComponents/Signup"));
const Profile = lazy(() => import("../components/AuthComponents/Profile"));
const ThankYou = lazy(() => import("../components/UserComponents/ThankYou"));
const UserDetails = lazy(() => import("../components/AdminComponents/UserDetails"));
const ResetPassword = lazy(() => import("../components/AuthComponents/ResetPassword"));
const StripeCheckout = lazy(() => import("../components/UserComponents/StripeCheckout"));
const UpdatePaymentMethod = lazy(() => import("../components/AuthComponents/Profile/UpdatePaymentMethod"));
const FilterUsers = lazy(() => import("../components/AdminComponents/FilterByDate"));
const CreateBulkPromoForm = lazy(() => import("../components/AdminComponents/BatchCoupons/create"));
const Export = lazy(() => import("../components/AdminComponents/BulkExport/Export.jsx"));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signup/:partnerId" component={Signup} />
          <Route exact path="/reset" component={ResetPassword} />
          <UserPrivate exact path="/" component={Home} />
          <UserPrivate exact path="/checkout" component={StripeCheckout} />
          <UserPrivate exact path="/profile" component={Profile} />
          <UserPrivate exact path="/thanks" component={ThankYou} />
          <AdminPrivate exact path="/admin" component={Users} />
          <AdminPrivate exact path="/admin/partners" component={ListPartners} />
          <AdminPrivate exact path="/admin/partners/create" component={NewPartner} />
          <AdminPrivate exact path="/admin/promos" component={ListBatches} />
          <AdminPrivate exact path="/admin/promos/create" component={CreateBulkPromoForm} />
          <AdminPrivate exact path="/admin/coupons/:id" component={ViewPromoCodes} />
          <AdminPrivate path="/admin/coupons" component={ViewPromoCodes} />
          <AdminPrivate exact path="/user/:id" component={UserDetails} />
          <AdminPrivate exact path="/admin/export" component={Export} />
          <AdminPrivate exact path="/admin/filter-users" component={FilterUsers} />
          <UserPrivate exact path="/update-payment-info" component={UpdatePaymentMethod} />
        </Switch>
      </Suspense>
    </Router>
  );
}
