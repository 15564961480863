import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  SET_USER,
  LOGOUT,
  UPDATE_PROFILE,
} from "../actions";

const initialState = {
  user: null,
  isLoading: false,
  isLoggedIn: false,
  error: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        user: null,
        isLoading: true,
        isLoggedIn: false,
        error: null,
      };
    case SIGNUP_USER:
      return {
        user: null,
        isLoading: true,
        isLoggedIn: false,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        user: action.payload,
        isLoading: false,
        isLoggedIn: true,
        error: null,
      };
    case SET_USER:
      return {
        user: action.payload,
        isLoading: false,
        isLoggedIn: Object.keys(action.payload).length ? true : false,
        error: null,
      };
    case UPDATE_PROFILE:
      return {
        user: state.user,
        isLoading: true,
        isLoggedIn: state.isLoggedIn,
        error: null,
      };
    case FORGOT_PASSWORD:
      return {
        user: null,
        isLoading: true,
        isLoggedIn: false,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: null,
        success: action.payload.msg,
      };
    case LOGOUT:
      return {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: null,
      };
    case LOGIN_ERROR:
      return {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload.message || action.payload.code,
      };
    default:
      return state;
  }
}
