import { mergeMap, map, catchError } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import {
  LOGIN_USER,
  SIGNUP_USER,
  FORGOT_PASSWORD,
  GET_USER,
  LOGOUT,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN,
  UPDATE_PROFILE,
} from "../actions/userActions";
import { loginUserSuccess, loginUserError, forgotPasswordSuccess, setUser } from "../actionCreators";
import { Firebase, db, auth } from "../../firebase/index";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, collection, setDoc } from "firebase/firestore";
import { toJSON } from "../../common/utils/serilizer";
import { isAdmin, setCookie } from "../../common/utils/auth";

export const loginUserEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN_USER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          const auth = getAuth(Firebase);
          signInWithEmailAndPassword(auth, action.payload.email, action.payload.password)
            .then((res) => {
              res.user.getIdTokenResult().then((res) => setCookie("isAdmin", isAdmin(res)));
              resolve(res);
            })
            .catch((err) => reject(err));
        })
      ).pipe(
        map((response) => loginUserSuccess(toJSON(response.user))),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const signupUserEpic = (action$) =>
  action$.pipe(
    ofType(SIGNUP_USER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          console.log("saving the object 4");
          const auth = getAuth(Firebase);

          createUserWithEmailAndPassword(auth, action.payload.email, action.payload.password)
            .then((res) => {
              delete action.payload.password;
              res.user.getIdTokenResult().then((res) => setCookie("isAdmin", isAdmin(res)));

              const usersRef = collection(db, "users");
              const userDocRef = doc(usersRef, res.user.uid);

              setDoc(userDocRef, {
                ...action.payload,
                uid: res.user.uid,
                sign_up_device: "Web Portal",
                registered_at: Math.floor(Date.now() / 1000),
              }).then(() => {
                resolve(res);
              });
            })
            .catch((err) => {
              console.log("error", err);
              reject(err);
            });
        })
      ).pipe(
        map((response) => loginUserSuccess(toJSON(response.user))),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const forgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType(FORGOT_PASSWORD),
    mergeMap((action) =>
      from(auth.sendPasswordResetEmail(action.payload.email)).pipe(
        map((response) => forgotPasswordSuccess({ msg: "Email Sent" })),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const logoutUserEpic = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    mergeMap((action) =>
      from(auth.signOut()).pipe(
        map((response) => setUser({})),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const getUserEpic = (action$) =>
  action$.pipe(
    ofType(GET_USER),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          const docRef = doc(db, "users", action.payload.uid);
          getDoc(docRef)
            .then(function (doc) {
              if (doc.exists) {
                resolve(doc.data());
              } else {
                resolve({});
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
      ).pipe(
        map((response) => setUser({ ...action.payload, profile: response })),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

const setFireBaseObj = (resolve, user, action) => {
  let userObj = {
    first_name: user.displayName,
    last_name: "",
    email: user.email,
    uid: user.uid,
    sign_up_device: "Web Portal",
    registered_at: Math.floor(Date.now() / 1000),
  };
  if (action.payload.affiliated_by) {
    userObj["affiliated_by"] = action.payload.affiliated_by;
  }
  let docRef = doc(collection(db, "users"), user.uid);
  setDoc(docRef, userObj, { merge: true }).then(() => {
    resolve(toJSON(user));
  });
};

export const googleLoginEpic = (action$) =>
  action$.pipe(
    ofType(GOOGLE_LOGIN),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          let provider = new GoogleAuthProvider();
          let auth = getAuth(Firebase);
          signInWithPopup(auth, provider)
            .then((res) => {
              let user = toJSON(res.user);
              res.user.getIdTokenResult().then((res) => setCookie("isAdmin", isAdmin(res)));
              let docRef = doc(collection(db, "users"), user.uid);
              getDoc(docRef).then((doc) => {
                if (!doc.exists()) {
                  setFireBaseObj(resolve, user, action);
                } else {
                  if (action.payload.type === "login") {
                    resolve(toJSON(user));
                  } else {
                    reject({ message: "Account already exists with this account. Try login with google" });
                  }
                }
              });
            })
            .catch(function (error) {
              reject(error);
            });
        })
      ).pipe(
        map((response) => setUser(response)),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const facebookLoginEpic = (action$) =>
  action$.pipe(
    ofType(FACEBOOK_LOGIN),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          const auth = getAuth(Firebase);
          const provider = new FacebookAuthProvider();
          signInWithPopup(auth, provider)
            .then((res) => {
              const user = toJSON(res.user);
              console.log(JSON.stringify(res.user));
              res.user.getIdTokenResult().then((res) => setCookie("isAdmin", isAdmin(res)));
              const docRef = doc(db, "users", user.uid);
              getDoc(docRef).then((docSnap) => {
                if (!docSnap.exists()) {
                  setFireBaseObj(resolve, user, action);
                } else {
                  if (action.payload.type === "login") {
                    resolve(toJSON(user));
                  } else {
                    reject({ message: "Account already exists with this account. Try login with facebook" });
                  }
                }
              });
            })
            .catch(function (error) {
              console.log("ERROR:", error);
              if (error.code === "auth/account-exists-with-different-credential" && error.email) {
                const email = error.email;
                const pendingCred = error.credential;
                auth.fetchSignInMethodsForEmail(email).then((providers) => {
                  if (providers.includes("google.com")) {
                    auth
                      .signInWithCredential(pendingCred)
                      .then((result) => {
                        console.log("RESULT", result);
                        result.user.linkWithCredential(pendingCred);
                        const user = toJSON(result.user);
                        const docRef = doc(db, "users", user.uid);
                        getDoc(docRef).then((docSnap) => {
                          if (!docSnap.exists()) {
                            setDoc(
                              docRef,
                              {
                                first_name: user.displayName,
                                last_name: "",
                                email: user.email,
                                uid: user.uid,
                              },
                              { merge: true }
                            ).then(() => {
                              resolve(toJSON(user));
                            });
                          } else {
                            resolve(toJSON(user));
                          }
                        });
                      })
                      .catch((error) => {
                        alert(JSON.stringify(error));
                      });
                  }
                  if (providers.includes("password")) {
                    const password = prompt("Please enter your password:", "");
                    auth
                      .signInWithEmailAndPassword(email, password)
                      .then((result) => {
                        console.log("RESULT", result);
                        result.user.linkWithCredential(pendingCred);
                        const user = toJSON(result.user);
                        const docRef = doc(db, "users", user.uid);
                        getDoc(docRef).then((docSnap) => {
                          if (!docSnap.exists()) {
                            setDoc(
                              docRef,
                              {
                                first_name: user.displayName,
                                last_name: "",
                                email: user.email,
                                uid: user.uid,
                              },
                              { merge: true }
                            ).then(() => {
                              resolve(toJSON(user));
                            });
                          } else {
                            resolve(toJSON(user));
                          }
                        });
                      })
                      .catch((error) => {
                        alert(JSON.stringify(error));
                      });
                  }
                });
              } else {
                reject(error);
              }
            });
        })
      ).pipe(
        map((response) => setUser(response)),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );

export const updateProfileEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROFILE),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          const { uid, profile } = action.payload;
          const docRef = doc(db, "users", uid);
          setDoc(docRef, profile, { merge: true })
            .then(() => {
              resolve(action.payload);
            })
            .catch((err) => {
              reject(err);
            });
        })
      ).pipe(
        map((response) => setUser(response)),
        catchError((error) => Promise.resolve(loginUserError(error)))
      )
    )
  );
