import React, { useState } from "react";
import { Firebase } from "../firebase/index";
import { SyncOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, getUser } from "../redux/actionCreators";
import { toJSON } from "../common/utils/serilizer";
import { isAdmin, setCookiesFromFirebase, setCookiesFromState } from "../common/utils/auth";
import { getAuth, onAuthStateChanged, getIdTokenResult } from "firebase/auth";

function AdminPrivateRooute({ state, user, history, component: Component, ...rest }) {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isVerified, setVerified] = useState(false);

  function verify_user() {
    if (!userState.user) {
      const auth = getAuth(Firebase);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          getIdTokenResult(user, true)
            .then((result) => {
              const admin = isAdmin(result);
              if (admin) {
                setCookiesFromFirebase(user, result.token, admin);
                setVerified(true);
                if (!userState.user) {
                  dispatch(setUser(toJSON(user))); // set firebase user in store
                  dispatch(getUser(toJSON(user))); // Get corresponding firestore user
                }
              } else {
                history.push("/");
              }
            })
            .catch((error) => {
              console.error("Error getting ID token:", error);
            });
        } else {
          history.push("/login");
        }
      });
    } else {
      setVerified(true);
      setCookiesFromState(userState);
    }
    return true;
  }

  if (!isVerified) {
    verify_user();
    return <SyncOutlined spin />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default withRouter(AdminPrivateRooute);

