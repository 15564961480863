import {
  LIST_PLANS,
  SUCCESS,
  ERROR,
  CREATE_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  SET_PAYMENT,
  GET_COUPON,
  GET_CARD_DETAILS,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
  RESET_PAYMENT,
} from "../actions/index";

export const listPlans = (payload) => ({
  type: LIST_PLANS,
  payload: payload,
});
export const resetPayment = (payload) => ({
  type: RESET_PAYMENT,
  payload: payload,
});
export const createCustomer = (payload) => ({
  type: CREATE_CUSTOMER,
  payload: payload,
});
export const getCustomer = (payload) => ({
  type: GET_CUSTOMER,
  payload: payload,
});
export const success = (payload) => ({
  type: SUCCESS,
  payload: payload,
});
export const setPayment = (payload) => ({
  type: SET_PAYMENT,
  payload: payload,
});
export const getCoupon = (payload) => ({
  type: GET_COUPON,
  payload: payload,
});

export const paymentError = (payload) => ({
  type: ERROR,
  payload: payload,
});
export const upgradeSubscription = (payload) => ({
  type: UPDATE_SUBSCRIPTION,
  payload: payload,
});

export const cancelSubscription = (payload) => ({
  type: CANCEL_SUBSCRIPTION,
  payload: payload,
});

export const getCardDetails = (payload) => ({
  type: GET_CARD_DETAILS,
  payload: payload,
});

export const updateDefaultPaymentMethod = (payload) => ({
  type: UPDATE_DEFAULT_PAYMENT_METHOD,
  payload: payload,
});

export const removePaymentMethod = (payload) => ({
  type: REMOVE_PAYMENT_METHOD,
  payload: payload,
});

