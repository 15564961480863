import {
  List_USERS,
  ADMIN_SUCCESS,
  ADMIN_ERROR,
  GET_STATISTICS,
  GET_USERS_BY_DATE,
  GET_USER_SUBSCRIPTION,
} from "../actions";

const initialState = {
  users: [],
  user: null,
  assessments: [],
  isLoading: false,
  error: null,
  progressStats: {},
  therapySessions: [],
  customCoupons: {},
  statistics: { total_users: 0, paid_users: 0 },
  paidUsers: [],
  users_by_date_count: null,
  subscriptionStatus: "Not Available",
};

export function adminReducer(state = initialState, action) {
  switch (action.type) {
    case List_USERS:
      return {
        users: action.payload.users || state.users,
        paidUsers: action.payload.paidUsers || state.paidUsers,
        assessments: [],
        therapySessions: [],
        user: null,
        isLoading: true,
        error: null,
        statistics: action.payload.statistics || state.statistics,
      };
    case GET_STATISTICS:
      return {
        users: state.users,
        paidUsers: state.paidUsers || action.payload.paidUsers,
        assessments: [],
        therapySessions: [],
        user: null,
        isLoading: true,
        error: null,
        statistics: { total_users: 0, paid_users: 0 },
      };
    case ADMIN_SUCCESS:
      return {
        users: action.payload.users || state.users,
        user: action.payload.user || state.user,
        assessments: action.payload.assessments || state.assessments,
        progressStats: action.payload.progressStats || state.progressStats,
        therapySessions: action.payload.therapySessions || state.therapySessions,
        customCoupons: action.payload.customCoupons || state.customCoupons,
        isLoading: false,
        error: null,
        statistics: action.payload.statistics || state.statistics,
        paidUsers: action.payload.paidUsers || state.paidUsers,
        users_by_date_count: action.payload.users_by_date_count || state.users_by_date_count,
        subscriptionStatus: action.payload.subscriptionStatus || state.subscriptionStatus,
      };
    case ADMIN_ERROR:
      return {
        users: [],
        user: null,
        assessments: [],
        therapySessions: [],
        isLoading: false,
        error: action.payload.code || action.payload.message,
        statistics: { total_users: 0, paid_users: 0 },
      };
    case GET_USERS_BY_DATE:
      return {
        ...state,
        users_by_date_count: state.users_by_date_count,
      };
    case GET_USER_SUBSCRIPTION:
      return {
        ...state,
        subscriptionStatus: state.subscriptionStatus,
      };
    default:
      return state;
  }
}
